<template>
  <div class="card">
    <DataTable :value="items" class="p-datatable-sm" showGridlines scrollable>
      <template #empty>
        <div class="text-center">Tidak ada data.</div>
      </template>
      <template #loading>
        <div class="text-center">Memuat data, harap tunggu...</div>
      </template>
      <Column field="si_at" header="Tgl Bukti" style="min-width: 10rem">
        <template #body="{ data }">
          {{ formatDate(data.si_at) }}
        </template>
      </Column>
      <Column field="si_no" header="No Bukti" style="min-width: 10rem" />
      <Column field="top_at" header="Tgl Jatuh Tempo" style="min-width: 10rem">
        <template #body="{ data }">
          {{ formatDate(data.top_at) }}
        </template>
      </Column>
      <Column
        field="dpp"
        header="DPP"
        style="min-width: 8rem; flex-grow: 1; justify-content: flex-end"
      >
        <template #body="{ data }">
          {{ formatCurrency(data.dpp, 2, 2) }}
        </template>
      </Column>
      <Column
        field="ppn"
        header="PPN"
        style="min-width: 8rem; flex-grow: 1; justify-content: flex-end"
      >
        <template #body="{ data }">
          {{ formatCurrency(data.ppn, 2, 2) }}
        </template>
      </Column>
      <Column
        field="jumlah"
        header="Jumlah"
        style="min-width: 8rem; flex-grow: 1; justify-content: flex-end"
      >
        <template #body="{ data }">
          {{ formatCurrency(data.jumlah, 2, 2) }}
        </template>
      </Column>
      <Column v-if="showButton" style="flex-grow: 1; justify-content: flex-end">
        <template #body="{ data }">
          <Button
            type="button"
            icon="pi pi-pencil"
            class="p-button-outlined p-button-secondary p-button-text mr-2"
            @click="$emit('edit', data)"
          />
          <Button
            type="button"
            icon="pi pi-trash"
            class="p-button-outlined p-button-danger p-button-text"
            @click="$emit('delete', data)"
          />
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import { formatCurrency, formatDate } from '@/helpers'

export default {
  components: {},
  props: {
    items: {
      type: Array,
      default() {
        return []
      },
    },
    showButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {}
  },
  mounted() {},
  watch: {},
  computed: {},
  methods: {
    formatCurrency(value, minDigit = 0, maxDigit = 0) {
      return formatCurrency(value, minDigit, maxDigit)
    },
    formatDate(value) {
      return formatDate(value)
    },
  },
}
</script>

<style scoped></style>
